import { Switch } from "@material-ui/core"

import { AsyncDropdown } from "src/components/Dropdown/AsyncDropdown"
import { PhoneNumber } from "src/components/Settings/EditField/PhoneNumber"
import {
  EditType,
  TEditField,
  TStoredValue,
} from "src/data/editField/editFieldTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MSelectV2 } from "src/ui/MSelect/MSelectV2"
import { MTextField } from "src/ui/MTextField/MTextField"
import { validatePhoneNumber } from "src/ui/PhoneNumberInput/phoneNumberUtil"

import NumberInput from "./NumberInput"
import { TimeSlider } from "./Slider"
import { SliderTimeRange } from "./SliderTimeRange"

export function ControlType<Setting, Payload>({
  value,
  conf,
  onChange,
  disabled,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  value: any
  conf: TEditField<Setting, Payload>
  onChange: ({
    newVal,
    saveVal,
    error,
  }: {
    newVal: TStoredValue
    saveVal?: TStoredValue
    error?: boolean
  }) => void
  disabled?: boolean
}) {
  const { _t } = useTranslate()

  switch (conf.type) {
    case EditType.TOGGLE:
      return (
        <div>
          <Switch
            checked={value === conf.trueVal}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              const newVal = Boolean(ev.target.checked)
                ? conf.trueVal
                : conf.falseVal
              onChange({ newVal })
            }}
            disabled={disabled}
          />
        </div>
      )

    case EditType.DROPDOWN: {
      return (
        <MSelectV2
          value={value}
          onChange={(value) => {
            const error = !value
            onChange({ newVal: value, error })
          }}
          disabled={disabled}
          options={conf.options.map((option) => ({
            key: option.key,
            label: _t(option.name),
            value: option.value,
            disabled: option.disabled,
            hidden: option.hidden,
          }))}
        />
      )
    }

    case EditType.DROPDOWN_SEARCH:
      return (
        <AsyncDropdown
          dataFetcher={conf.search}
          onSelected={(o) => {
            onChange({ newVal: o.name, saveVal: o.id, error: !o.name || !o.id })
          }}
          valueInitial={conf.defaultValue}
          optionsInitial={[]} // Get current options?
        />
      )

    case EditType.INPUT_NUMBER:
      return (
        <NumberInput
          label={conf.label}
          value={value}
          min={conf.min}
          max={conf.max}
          unit={conf.unit || ""}
          warningMin={conf.warningMin}
          warningMax={conf.warningMax}
          setValue={(newVal: number, error) => {
            onChange({ newVal, error })
          }}
        />
      )

    case EditType.INPUT_TEXT:
      // TODO WEB-XXX: Create wrapper component with built-in 'Save' button, to make inline editing possible
      // TODO WEB-XXX: Alternatively, add debounce functionality
      return (
        <MTextField
          maxLength={conf.maxLength}
          value={value}
          disabled={disabled}
          onChange={(value) => {
            const error = !value
            onChange({ newVal: value, error })
          }}
        />
      )

    case EditType.PHONE_NUMBER:
      return (
        <PhoneNumber
          phoneNumber={value}
          onChange={(newVal: string) => {
            const error = !validatePhoneNumber(newVal)
            onChange({ newVal, error })
          }}
        />
      )

    case EditType.SLIDER_RANGE:
      return (
        <SliderTimeRange
          value={value}
          setValue={(newVal: string) => {
            onChange({ newVal })
          }}
          clockType={conf.clockType || "24"}
        />
      )

    case EditType.SLIDER:
      return (
        <TimeSlider
          value={value}
          min={conf.min}
          max={conf.max}
          unit={conf.unit || ""}
          warning={conf.warning}
          setValue={(newVal: number | string) => {
            onChange({ newVal })
          }}
        />
      )

    case EditType.STATIC:
      return <div>{_t(value)}</div>

    default: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
      const type = (conf as any).type
      return <div>ERROR: config field '{type}' not implemented</div>
    }
  }
}
